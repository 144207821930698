import { cssVar, defineStyle, defineStyleConfig } from "@chakra-ui/react";

const $startColor = cssVar("skeleton-start-color");
const $endColor = cssVar("skeleton-end-color");

const inputSkeleton = defineStyle({
  borderRadius: "8px",
  _light: {
    [$startColor.variable]: "var(--chakra-colors-lightDeepBlue)",
    [$endColor.variable]: "#eff5fe",
  },
  height: "36px",
  width: "325px",
});

const textAreaSkeleton = defineStyle({
  borderRadius: "8px",
  _light: {
    [$startColor.variable]: "var(--chakra-colors-lightDeepBlue)",
    [$endColor.variable]: "#eff5fe",
  },
  height: "80px",
  width: "325px",
});

const roomsSkeleton = defineStyle({
  borderRadius: "8px",
  _light: {
    [$startColor.variable]: "var(--chakra-colors-lightDeepBlue)",
    [$endColor.variable]: "#eff5fe",
  },
  height: "60px",
  width: "325px",
});

export const skeletonTheme = defineStyleConfig({
  variants: {
    inputSkeleton,
    textAreaSkeleton,
    roomsSkeleton,
  },
});
