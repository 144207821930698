import { useTranslation } from "react-i18next";
import { EmptyView } from "../EmptyView/EmptyView";
import { useAppSelector } from "../../store";
import { Agent } from "../Agent/Agent";
import { getAgents, selectAgents } from "../Agent/agentSlice";
import { useEffect } from "react";
import { useAppDispatch } from "@anthill/domino-ui-base";

export const AgentView = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const agents = useAppSelector(selectAgents);

  useEffect(() => {
    dispatch(getAgents());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <main>
      {agents.length === 0 ? (
        <Agent agents={agents} dataFiles={[]} />
      ) : (
        <EmptyView
          title={t("You haven't added any agents yet")}
          description={t("To get started, create an agent")}
          createLink="/agents/new-agent"
          createButtonText={t("Create a new agent")}
        />
      )}
    </main>
  );
};
