import { Option } from "../hooks/useSelect";
import { AgentModel } from "../components/Agent/AgentModel";
import { DataFilesModel } from "../components/DataFiles/DataFilesModel";

export function bringDataToOption(data: AgentModel[] | DataFilesModel[], checkedIds?: string[]): Option[] {
  return data.map(item => ({
    id: item.id,
    name: item.name,
    isChecked: !!(checkedIds && checkedIds.includes(item.id)),
  }));
}
