import { Center, ChakraProvider, Spinner } from "@chakra-ui/react";
import { selectLoadingState } from "./LoadingSlice";
import React from "react";
import { theme } from "../chakra/theme";
import { useSelector } from "react-redux";
import s from "./Loader.module.scss";

interface Props {
  scope: string;
}

export const Loader = ({ scope }: Props) => {
  const loader: string[] = useSelector(selectLoadingState);
  const isLoading = loader?.find((a) => a === scope);

  if (!isLoading) {
    return <></>;
  }

  return (
    <ChakraProvider theme={theme}>
      <Center className={s.spinnerContainer}>
        <Spinner className={s.spinner} />
      </Center>
    </ChakraProvider>
  );
};
