import { baseApi } from "@anthill/domino-ui-base";
import { AgentModel } from "./AgentModel";

export const getAgents = () => {
  return baseApi.httpGet("/agents");
};

export type CreateAgentType = Pick<AgentModel, "name" | "description" | "prompt" | "dataFileIds">;
export const createAgent = (agent: CreateAgentType) => {
  return baseApi.httpPost("/agents", agent);
};

export const updateAgent = (agent: AgentModel) => {
  return baseApi.httpPut(`/agents/${agent.id}`, agent);
};

export const deleteAgent = (agentId: string) => {
  return baseApi.httpDelete(`/agents/${agentId}`);
};
