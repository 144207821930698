import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AgentState } from "./AgentState";
import { AgentModel } from "./AgentModel";
import { ApplicationState } from "../../ApplicationState";
import { CreateAgentType } from "./AgentApi";

const initialState: AgentState = {
  agents: [],
};

export const agentSlice = createSlice({
  name: "agentSlice",
  initialState,
  reducers: {
    getAgents: state => {
      return {
        ...state,
      };
    },
    getAgentsCompleted: (state, action: PayloadAction<AgentModel[]>) => {
      return {
        ...state,
        agents: action.payload,
      };
    },
    createAgent: (state, action: PayloadAction<CreateAgentType>) => {
      return {
        ...state,
      };
    },
    updateAgent: (state, action: PayloadAction<AgentModel>) => {
      return {
        ...state,
      };
    },
    deleteAgent: (state, action: PayloadAction<string>) => {
      return {
        ...state,
      };
    },
  },
});

export const { getAgents, getAgentsCompleted, createAgent, updateAgent, deleteAgent } = agentSlice.actions;

export const selectAgents = (state: ApplicationState) => state.app.agentState.agents;
