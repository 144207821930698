import * as React from "react";
import { Provider } from "react-redux";
import { createRoot } from "react-dom/client";
import { Reducer } from "redux";
import { Saga } from "redux-saga";
import { createStore } from "./store";
import { ChakraProvider } from "@chakra-ui/react";
import { theme } from "./chakra/theme";
import { BaseAppSettings } from "./BaseAppSettings";
import ReactGA from "react-ga4";
import * as Sentry from "@sentry/react";

declare const appSettings: BaseAppSettings;

if (appSettings.GAIds?.length) {
  ReactGA.initialize(
    appSettings.GAIds.map((x) => {
      return {
        trackingId: x,
        gaOptions: {
          allowAdPersonalizationSignals: false,
          allowAdFeatures: false,
        },
      };
    })
  );
}

export abstract class BaseApp<T> {
  rootReducer: Reducer<T>;
  rootSaga: Saga<any[]>;

  constructor(rootReducer: Reducer<T>, rootSaga: Saga<any[]>) {
    this.rootReducer = rootReducer;
    this.rootSaga = rootSaga;
  }

  start(App: React.FunctionComponent) {
    if (appSettings.GAIds?.length) {
      ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    }
    process.env.USESENTRY === "true" &&
      Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN,
        integrations: [Sentry.replayIntegration()],
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
      });
    const store = createStore<T>(this.rootReducer, this.rootSaga);

    const container = document.getElementById("root");
    const root = createRoot(container!);
    root.render(
      <Provider store={store}>
        <ChakraProvider theme={theme}>
          <App />
        </ChakraProvider>
      </Provider>
    );
  }
}
