import { useEffect } from "react";
import { useToast } from "@chakra-ui/react";
import { clearNotify, selectNotification } from "./NotificationSlice";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../store";
import React from "react";

export const NotificationContainer = () => {
  const notification = useSelector(selectNotification);
  const dispatch = useAppDispatch();
  const toast = useToast();

  useEffect(() => {
    if (notification) {
      const duration =
        notification.duration === undefined ? 3000 : notification.duration;
      dispatch(clearNotify());

      toast({
        title: notification.message,
        status: notification.type,
        isClosable: duration > 0,
        position: "bottom-left",
        duration: duration === 0 ? Number.MAX_VALUE : duration,
      });
    }
  }, [dispatch, notification, toast]);

  return <></>;
};
