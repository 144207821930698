import { createBrowserRouter, createRoutesFromElements, Navigate, Route, RouterProvider } from "react-router-dom";
import { AuthFrameContainer, NotificationContainer } from "@anthill/domino-ui-base";
import { AgentView } from "./components/AgentView/AgentView";
import { CreateAgent } from "./components/CreateAgent/CreateAgent";
import { DataFilesView } from "./components/DataFilesView/DataFilesView";
import { CreateDataFiles } from "./components/CreateDataFiles/CreateDataFiles";
import "./App.css";

function App() {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/">
        <Route path="agents">
          <Route path="" element={<AgentView />} />
          <Route path="new-agent" element={<CreateAgent />} />
        </Route>
        <Route path="data-files">
          <Route path="" element={<DataFilesView />} />
          <Route path="new-data-file" element={<CreateDataFiles />} />
        </Route>
        <Route path="" element={<Navigate to="/agents" />} />
      </Route>,
    ),
  );

  return (
    <>
      <RouterProvider router={router} />
      <AuthFrameContainer pluginName="ai" />
      <NotificationContainer />
    </>
  );
}

export default App;
