// import { useTranslation } from "react-i18next";
// import { EmptyView } from "../EmptyView/EmptyView";
import { DataFiles } from "../DataFiles/DataFiles";

export const DataFilesView = () => {
  // const { t } = useTranslation();

  return (
    <div>
      {/* <EmptyView
        title={t("You haven't added any data files yet")}
        description={t("To get started, create an data file")}
        createLink="/data-files/new-data-files"
        createButtonText={t("Create a new data file")}
      /> */}
      <DataFiles />
    </div>
  );
};
