import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { NotificationModel } from "./NotificationModel";
import { NotificationState } from "./NotificationState";

const initialState: NotificationState = {};

export const notificationSlice = createSlice({
  name: "notificationSlice",
  initialState,
  reducers: {
    notify: (state: NotificationState, action: PayloadAction<NotificationModel>) => {
      return {
        ...state,
        notification: action.payload,
      };
    },
    clearNotify: (state: NotificationState) => {
      return {
        ...initialState,
      };
    },
  },
});

export const { notify, clearNotify } = notificationSlice.actions;

export const selectNotification = (state: {notificationState: NotificationState}) => state.notificationState.notification;

export default notificationSlice.reducer;