import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, Flex, Text } from "@chakra-ui/react";
import { useInput } from "../../hooks/useInput";
import { InputText } from "../../ui/InputText/InputText";
import { InputTextarea } from "../../ui/InputTextarea/InputTextarea";
import { Option, useSelect } from "../../hooks/useSelect";
import { InputSelect } from "../../ui/InputSelect/InputSelect";
import s from "./CreateAgent.module.scss";
import { ChapterWrapper } from "../../ui/ChapterWrapper/ChapterWrapper";
import { useAppDispatch } from "@anthill/domino-ui-base";
import { CreateAgentType } from "../Agent/AgentApi";
import { createAgent } from "../Agent/agentSlice";

const INIT_DATA_FILES: Option[] = [
  { name: "База 1", isChecked: false, id: "sdad323232sada1" },
  { name: "База 2", isChecked: false, id: "sdad323232sada2" },
  { name: "База 3", isChecked: false, id: "sdad323232sada3" },
];

export const CreateAgent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [name, changeName, clearName] = useInput("");
  const [description, changeDescription, clearDescription] = useInput("");
  const [prompt, changePrompt, clearPrompt] = useInput("");
  const [dataFiles, changeDataFiles, valueDataFiles] = useSelect(INIT_DATA_FILES, true);

  const createDataFiles = () => navigate("/data-files/new-data-file");
  const additionalButton = { text: t("Add data files"), onClick: createDataFiles };

  const handleCancel = () => navigate(-1);
  const handleSave = () => {
    if (name) {
      const agent: CreateAgentType = {
        name,
        description,
        prompt,
        dataFileIds: dataFiles.filter(dataFile => dataFile.isChecked).map(dataFile => dataFile.id),
      };

      dispatch(createAgent(agent));
    }
  };

  return (
    <ChapterWrapper>
      <Flex direction="column" rowGap="16px" className={s.form}>
        <Text className={s.title}>{t("New agent")}</Text>
        <Flex direction="column" rowGap="16px" className={s.fields}>
          <InputText value={name} onChange={changeName} label={t("Name")} placeholder={t("Enter name")} onClear={clearName} />
          <InputText
            value={description}
            onChange={changeDescription}
            label={t("Description")}
            placeholder={t("Enter description")}
            onClear={clearDescription}
          />
          <InputTextarea
            value={prompt}
            onChange={changePrompt}
            label={t("Prompt")}
            placeholder={t("Enter prompt")}
            onClear={clearPrompt}
          />
          <InputSelect
            value={valueDataFiles}
            options={dataFiles}
            onChange={changeDataFiles}
            label={t("Data files")}
            placeholder={t("Select data files")}
            isMultiselect={true}
            additionalButton={additionalButton}
          />
        </Flex>
        <Flex justifyContent="space-between">
          <Button variant="dominoOutlineViolet" onClick={handleCancel}>
            {t("Cancel")}
          </Button>
          <Button variant="dominoViolet" onClick={handleSave}>
            {t("Save")}
          </Button>
        </Flex>
      </Flex>
    </ChapterWrapper>
  );
};
