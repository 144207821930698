import { Button, Modal, ModalBody, ModalContent, ModalFooter, Text } from "@chakra-ui/react";
import s from "./ConfirmModal.module.scss";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  text: string;
  onCancel: () => void;
  onConfirm: () => void;
  cancelText: string;
  confirmText: string;
}

export const ConfirmModal = ({ isOpen, onClose, text, cancelText, confirmText, onCancel, onConfirm }: Props) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalContent className={s.modal} boxShadow="none">
        <ModalBody>
          <Text className={s.text}>{text}</Text>
        </ModalBody>
        <ModalFooter className={s.footer}>
          <Button onClick={onCancel} variant="dominoOutlineViolet">
            {cancelText}
          </Button>
          <Button onClick={onConfirm} variant="dominoPrimaryRed">
            {confirmText}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
