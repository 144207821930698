import { Flex } from "@chakra-ui/react";
import { ReactNode } from "react";
import s from "./ChapterWrapper.module.scss";

interface Props {
  children: ReactNode;
}

export const ChapterWrapper = ({ children }: Props) => {
  return <Flex className={s.wrapper}>{children}</Flex>;
};
