import React from "react";
import { useEffect } from "react";
import { BaseAppSettings } from "./BaseAppSettings";
import { useSelector } from "react-redux";
import {
  authSlice,
  selectIsAuthRejected,
  selectIsAuthRequested,
  selectToken,
} from "./auth/AuthSlice";
import { useAppDispatch } from "./store";

declare const appSettings: BaseAppSettings;
interface TokenMessage {
  token?: string;
}

interface Props {
  pluginName: string;
}

export const AuthFrameContainer = ({ pluginName }: Props) => {
  const token = useSelector(selectToken);
  const isRejected = useSelector(selectIsAuthRejected);
  const isRequested = useSelector(selectIsAuthRequested);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const handler = (
      ev: MessageEvent<{ type: string; message: TokenMessage }>
    ) => {
      if (typeof ev.data !== "object") return;
      if (!ev.data.type) return;
      if (ev.data.type !== "tokenMessage") return;
      if (!ev.data.message) return;

      if (ev.data.message.token) {
        dispatch(
          authSlice.actions.tokenReceived({
            accessToken: ev.data.message.token,
          })
        );
      } else {
        dispatch(authSlice.actions.tokenRejected());
      }
    };

    window.addEventListener("message", handler);
    return () => window.removeEventListener("message", handler);
  }, []);

  if (token || isRejected || !isRequested) {
    return <></>;
  }

  return (
    <>
      <iframe
        id="auth-frame"
        src={`${
          appSettings.idpUrl
        }/plugin/auth/${pluginName}?origin=${encodeURIComponent(
          window.origin
        )}`}
        style={{ display: "none" }}
      ></iframe>
    </>
  );
};
