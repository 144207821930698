import { useTranslation } from "react-i18next";
import { ChapterWrapper } from "../../ui/ChapterWrapper/ChapterWrapper";
import { Flex, Icon } from "@chakra-ui/react";
import { InputSelect } from "../../ui/InputSelect/InputSelect";
import { Option, useSelect } from "../../hooks/useSelect";
import { useNavigate } from "react-router-dom";
import { InputText } from "../../ui/InputText/InputText";
import { useInput } from "../../hooks/useInput";
import { InputTextarea } from "../../ui/InputTextarea/InputTextarea";
import { ReactComponent as trashIcon } from "../../assets/icons/TrashGrey.svg";
import s from "./DataFiles.module.scss";
import { ConfirmModal } from "../../ui/ConfirmModal/ConfirmModal";
import { useState } from "react";

const INIT_DATA_FILES: Option[] = [
  { name: "Файл 1", isChecked: false, id: "sdad323232sada1" },
  { name: "Файл 2", isChecked: false, id: "sdad323232sada2" },
  { name: "Файл 3", isChecked: false, id: "sdad323232sada3" },
];

export const DataFiles = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isModalShown, setIsModalShown] = useState<boolean>(false);

  const [dataFiles, changeDataFiles, dataFilesValue] = useSelect(INIT_DATA_FILES);
  const [description, changeDescription, clearDescription] = useInput("");
  const [prompt, changePrompt, clearPrompt] = useInput("");

  const createDataFiles = () => navigate("/data-files/new-data-file");
  const additionalButton = { text: t("Add data files"), onClick: createDataFiles };

  const handleOpen = () => setIsModalShown(true);
  const handleClose = () => setIsModalShown(false);
  const handleConfirm = () => setIsModalShown(false);

  return (
    <ChapterWrapper>
      <Flex direction="column" rowGap="16px" className={s.form}>
        <InputSelect
          label={t("Data files")}
          onChange={changeDataFiles}
          options={dataFiles}
          placeholder={t("Select data files")}
          value={dataFilesValue}
          additionalButton={additionalButton}
        />
        <InputText
          label={t("Description")}
          onChange={changeDescription}
          onClear={clearDescription}
          placeholder={t("Enter description")}
          value={description}
        />
        <InputTextarea
          label={t("Prompt")}
          onChange={changePrompt}
          onClear={clearPrompt}
          placeholder={t("Enter prompt")}
          value={prompt}
        />
        <Icon
          color="#8592A3"
          _hover={{ color: "#EB5038", bg: "#F1F4F9", borderRadius: "4px" }}
          cursor="pointer"
          as={trashIcon}
          boxSize="24px"
          onClick={handleOpen}
        />
        <ConfirmModal
          cancelText={t("Cancel")}
          confirmText={t("Delete")}
          isOpen={isModalShown}
          onCancel={handleClose}
          onClose={handleClose}
          onConfirm={handleConfirm}
          text={t("Delete data file?")}
        />
      </Flex>
    </ChapterWrapper>
  );
};
