import { put } from "redux-saga/effects";
import { UnauthorizedError } from "./BaseModels";
import { NotificationModel } from "./notifications/NotificationModel";
import { notificationSlice } from "./notifications/NotificationSlice";

export function* handleException(error: unknown) {
  if (error instanceof UnauthorizedError) {
    console.warn(error.message);
    return;
  }

  if ((error as Error).message) {
    const notification: NotificationModel = {
      message: (error as Error).message,
      type: "error",
    };

    yield put(notificationSlice.actions.notify(notification));
    return;
  }

  throw error;
}
