import { InputGroup, InputRightElement, Textarea, TextareaProps } from "@chakra-ui/react";
import { InputWrapper } from "../InputWrapper/InputWrapper";
import { ReactComponent as CrossIcon } from "../../assets/icons/cross.svg";
import { ChangeEventHandler, useId } from "react";
import s from "./InputTextarea.module.scss";

interface Props extends TextareaProps {
  value: string;
  errorMessage?: string;
  placeholder: string;
  label: string;
  onChange: ChangeEventHandler<HTMLTextAreaElement>;
  onClear: () => void;
}

const DEFAULT_ROWS = 10;

export const InputTextarea = ({ value, onChange, placeholder, errorMessage, label, onClear, rows = DEFAULT_ROWS }: Props) => {
  const inputId = useId();

  return (
    <InputWrapper label={label} errorMessage={errorMessage} inputId={inputId}>
      <InputGroup>
        <Textarea
          className={s.input}
          value={value}
          onChange={onChange}
          isInvalid={!!errorMessage}
          errorBorderColor="red.500"
          placeholder={placeholder}
          id={inputId}
          rows={rows}
        />
        <InputRightElement>
          {value && <CrossIcon boxSize={1} cursor={"pointer"} className={s.crossIcon} onClick={onClear} />}
        </InputRightElement>
      </InputGroup>
    </InputWrapper>
  );
};
