import { Box, Button, Flex, Icon } from "@chakra-ui/react";
import { InputSelect } from "../../ui/InputSelect/InputSelect";
import { InputText } from "../../ui/InputText/InputText";
import { InputTextarea } from "../../ui/InputTextarea/InputTextarea";
import { useTranslation } from "react-i18next";
import { useSelect } from "../../hooks/useSelect";
import { useEffect, useRef, useState } from "react";
import { useInput } from "../../hooks/useInput";
import { useNavigate } from "react-router-dom";
import { ReactComponent as trashIcon } from "../../assets/icons/TrashGrey.svg";
import { InputWrapper } from "../../ui/InputWrapper/InputWrapper";
import { CopyField } from "../../ui/CopyField/CopyField";
import { ConfirmModal } from "../../ui/ConfirmModal/ConfirmModal";
import { AgentModel } from "./AgentModel";
import { bringDataToOption } from "../../utils/bringDataToOption";
import { DataFilesModel } from "../DataFiles/DataFilesModel";
import { useAppDispatch } from "@anthill/domino-ui-base";
import { deleteAgent, updateAgent } from "./agentSlice";
import { getSelectedAgent } from "./utils/utils";
import s from "./Agent.module.scss";
import { ChapterWrapper } from "../../ui/ChapterWrapper/ChapterWrapper";

const FAKE_TOKEN = "dsadas23sdfhkdfui_232sda";

interface Props {
  agents: AgentModel[];
  dataFiles: DataFilesModel[];
}

export const Agent = ({ agents, dataFiles }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isModalShown, setIsModalShown] = useState<boolean>(false);

  const [agentsOptions, changeAgents, agentsValue] = useSelect(bringDataToOption(agents));
  const [token] = useState<string>(FAKE_TOKEN);
  const [description, changeDescription, clearDescription, setDescription] = useInput("");
  const [prompt, changePrompt, clearPrompt, setPrompt] = useInput("");
  const [dataFilesOptions, changeDataFiles, dataFilesValue, setDataFilesOptions] = useSelect(bringDataToOption(dataFiles), true);

  const initChanges = useRef<boolean>(false);
  const [wasChanges, setWasChanges] = useState<boolean>(false);

  const setAgentData = () => {
    const selectedAgent = getSelectedAgent(agents, agentsValue);
    if (selectedAgent) {
      const agentDataFiles = dataFiles.filter(dataFile => selectedAgent?.dataFileIds.includes(dataFile.id));

      setDescription(selectedAgent.description);
      setPrompt(selectedAgent.prompt);
      setDataFilesOptions(bringDataToOption(agentDataFiles, selectedAgent.dataFileIds));
    }
  };
  useEffect(() => {
    setWasChanges(false);

    setAgentData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agentsValue]);
  useEffect(() => {
    if (initChanges.current) {
      setWasChanges(true);
    } else {
      initChanges.current = true;
    }
  }, [description, prompt, dataFilesValue]);

  const handleDiscardChanges = () => {
    setAgentData();
    setWasChanges(false);
  };
  const handleSaveChanges = () => {
    if (agentsValue) {
      const id = getSelectedAgent(agents, agentsValue)?.id;
      if (id) {
        const agent: AgentModel = {
          id,
          name: agentsValue,
          description,
          prompt,
          dataFileIds: dataFilesOptions.filter(dataFile => dataFile.isChecked).map(dataFile => dataFile.id),
        };

        dispatch(updateAgent(agent));
      }
    }
  };

  const createAgent = () => navigate("/agents/new-agent");
  const createDataFiles = () => navigate("/data-files/new-data-file");
  const agentAdditionalButton = { text: t("Add agent"), onClick: createAgent };
  const dataFilesAdditionalButton = { text: t("Add data files"), onClick: createDataFiles };

  const handleOpen = () => setIsModalShown(true);
  const handleClose = () => setIsModalShown(false);
  const handleConfirm = () => {
    const selectedAgent = getSelectedAgent(agents, agentsValue);
    selectedAgent && dispatch(deleteAgent(selectedAgent.id));
    handleClose();
  };

  return (
    <ChapterWrapper>
      <Flex direction="column" rowGap="16px" className={s.form}>
        {wasChanges && (
          <Flex alignItems="center" alignSelf="end" columnGap="16px">
            <Button onClick={handleDiscardChanges} variant="dominoOutlineViolet">
              {t("Discard changes")}
            </Button>
            <Button onClick={handleSaveChanges} variant="dominoViolet">
              {t("Save")}
            </Button>
          </Flex>
        )}
        <Flex columnGap="16px">
          <Box width="35%">
            <InputSelect
              label={t("Agent")}
              onChange={changeAgents}
              options={agentsOptions}
              placeholder={t("Select agent")}
              value={agentsValue}
              additionalButton={agentAdditionalButton}
            />
          </Box>
          <Box width="65%">
            <InputWrapper label={t("Token")}>
              <CopyField value={token} />
            </InputWrapper>
          </Box>
        </Flex>
        <InputText
          label={t("Description")}
          onChange={changeDescription}
          onClear={clearDescription}
          placeholder={t("Enter description")}
          value={description}
        />
        <InputTextarea
          label={t("Prompt")}
          onChange={changePrompt}
          onClear={clearPrompt}
          placeholder={t("Enter prompt")}
          value={prompt}
        />
        <InputSelect
          label={t("Data files")}
          onChange={changeDataFiles}
          options={dataFilesOptions}
          placeholder={t("Select data files")}
          value={dataFilesValue}
          additionalButton={dataFilesAdditionalButton}
          isMultiselect
        />
        <Icon
          color="#8592A3"
          _hover={{ color: "#EB5038", bg: "#F1F4F9", borderRadius: "4px" }}
          cursor="pointer"
          as={trashIcon}
          boxSize="24px"
          onClick={handleOpen}
        />
        <ConfirmModal
          cancelText={t("Cancel")}
          confirmText={t("Delete")}
          isOpen={isModalShown}
          onCancel={handleClose}
          onClose={handleClose}
          onConfirm={handleConfirm}
          text={t("Delete agent?")}
        />
      </Flex>
    </ChapterWrapper>
  );
};
