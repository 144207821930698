import { ChangeEvent, Dispatch, SetStateAction, useState } from "react";

type ReturnValue = [
  string,
  (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => void,
  () => void,
  Dispatch<SetStateAction<string>>,
];

export function useInput(init: string): ReturnValue {
  const [value, setValue] = useState<string>(init);

  const onChange = (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => setValue(e.target.value);
  const onClear = () => setValue("");

  return [value, onChange, onClear, setValue];
}
