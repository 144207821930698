import { Flex, Icon } from "@chakra-ui/react";
import { ReactComponent as copy } from "../../assets/icons/copyIcon.svg";
import s from "./CopyField.module.scss";

interface Props {
  value: string;
}

export const CopyField = ({ value }: Props) => {
  const handleClick = () => navigator.clipboard.writeText(value);

  return (
    <Flex alignItems="center" justifyContent="space-between" className={s.field}>
      {value}
      <Icon as={copy} color="#8592A3" alt="CopyIcon" boxSize="20px" _hover={{ color: "#3E5E95" }} onClick={handleClick} />
    </Flex>
  );
};
