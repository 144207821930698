/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AuthState } from "./AuthState";
import { TokenModel } from "./AuthModel";
//import { RootState } from "../state/store";

const initialState: AuthState = {
  isRequested: false,
  isRejected: false,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    getToken: (state) => {
      return {
        ...state,
        isRequested: true,
      };
    },
    tokenReceived: (state, action: PayloadAction<TokenModel>) => {
      return {
        ...state,
        apiToken: action.payload,
        isRequested: false,
      };
    },
    tokenRejected: (state) => {
      return {
        ...state,
        apiToken: undefined,
        isRejected: true,
        isRequested: false,
      };
    },
    refreshToken: (state) => {
      return {
        ...state,
        apiToken: undefined,
        isRequested: true,
      };
    },
    logout: (state, action: PayloadAction) => {
      return {
        ...state,
        apiToken: undefined,
        isRequested: false,
      };
    },
  },
});

export const selectToken = (state: { authState: AuthState }) =>
  state.authState.apiToken;

export const selectIsAuthRejected = (state: { authState: AuthState }) =>
  state.authState.isRejected;

export const selectIsAuthRequested = (state: { authState: AuthState }) =>
  state.authState.isRequested;

export const { getToken, tokenReceived, tokenRejected, logout, refreshToken } =
  authSlice.actions;

export default authSlice.reducer;
