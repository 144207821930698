import { useId } from "react";
import { InputWrapper } from "../InputWrapper/InputWrapper";
import { Button, Flex, Icon, Menu, MenuButton, MenuItem, MenuList, Text } from "@chakra-ui/react";
import { Option } from "../../hooks/useSelect";
import { ReactComponent as ArrowDrop } from "../../assets/icons/ArrowDrop.svg";
import { ReactComponent as Plus } from "../../assets/icons/GreyPlus.svg";

interface AdditionalButton {
  text: string;
  onClick: () => void;
}

interface Props {
  options: Option[];
  onChange: (option: Option) => void;
  value: string;
  errorMessage?: string;
  placeholder: string;
  label: string;
  isMultiselect?: boolean;
  additionalButton?: AdditionalButton;
}

export const InputSelect = ({
  options,
  onChange,
  value,
  errorMessage,
  placeholder,
  label,
  isMultiselect,
  additionalButton,
}: Props) => {
  const inputId = useId();

  return (
    <InputWrapper label={label} errorMessage={errorMessage} inputId={inputId}>
      <Menu variant="dominoBroadcastMenu" matchWidth closeOnSelect={!isMultiselect}>
        <MenuButton
          as={Button}
          variant="dominoDefaultMenuBtn"
          _active={{ borderColor: "mainPurple", borderBottom: "mainPurple", borderRadius: "8px" }}
          rightIcon={<ArrowDrop />}
        >
          {value ? value : <Text color="#8592A3">{placeholder}</Text>}
        </MenuButton>
        <MenuList>
          {options.map(option => (
            <MenuItem key={option.name} onClick={() => onChange(option)}>
              {option.name}
            </MenuItem>
          ))}
          {additionalButton && (
            <MenuItem onClick={additionalButton.onClick}>
              <Flex w="100%" gap="8px" align="center">
                <Icon as={Plus} boxSize="20px" />
                <Text>{additionalButton.text}</Text>
              </Flex>
            </MenuItem>
          )}
        </MenuList>
      </Menu>
    </InputWrapper>
  );
};
