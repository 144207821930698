import { useTranslation } from "react-i18next";
import { Button, Flex, Text } from "@chakra-ui/react";
import { useInput } from "../../hooks/useInput";
import { InputText } from "../../ui/InputText/InputText";
import { InputTextarea } from "../../ui/InputTextarea/InputTextarea";
import { ChapterWrapper } from "../../ui/ChapterWrapper/ChapterWrapper";
import s from "./CreateDataFiles.module.scss";
import { useNavigate } from "react-router-dom";

export const CreateDataFiles = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [name, changeName, clearName] = useInput("");
  const [description, changeDescription, clearDescription] = useInput("");
  const [content, changeContent, clearContent] = useInput("");

  const handleCancel = () => navigate(-1);

  return (
    <ChapterWrapper>
      <Flex direction="column" rowGap="16px" className={s.form}>
        <Text className={s.title}>{t("New data file")}</Text>
        <Flex direction="column" rowGap="16px" className={s.fields}>
          <InputText value={name} onChange={changeName} label={t("Name")} placeholder={t("Enter name")} onClear={clearName} />
          <InputText
            value={description}
            onChange={changeDescription}
            label={t("Description")}
            placeholder={t("Enter description")}
            onClear={clearDescription}
          />
          <InputTextarea
            value={content}
            onChange={changeContent}
            label={t("Content")}
            placeholder={t("Enter content")}
            onClear={clearContent}
          />
        </Flex>
        <Flex justifyContent="space-between">
          <Button variant="dominoOutlineViolet" onClick={handleCancel}>
            {t("Cancel")}
          </Button>
          <Button variant="dominoViolet">{t("Save")}</Button>
        </Flex>
      </Flex>
    </ChapterWrapper>
  );
};
