import { useDispatch, useStore } from "react-redux";
import { combineReducers, configureStore, Reducer } from "@reduxjs/toolkit";
import createSagaMiddleware, { Saga } from "redux-saga";
import authReducer from "./auth/AuthSlice";
import loadingReducer from "./loader/LoadingSlice";
import notificationReducer from "./notifications/NotificationSlice";
import { fork } from "redux-saga/effects";
import { authSaga } from "./auth/AuthSaga";

export function createStore<T>(rootReducer: Reducer<T>, rootSaga: Saga<any[]>) {
  const sagaMiddleware = createSagaMiddleware();

  const newReducer = combineReducers({
    authState: authReducer,
    loadingState: loadingReducer,
    notificationState: notificationReducer,
    app: rootReducer,
  });

  const store = configureStore({
    reducer: newReducer,
    middleware: [sagaMiddleware],
  });

  const fullSaga = function* () {
    yield fork(authSaga);
    yield fork(rootSaga);
  };

  sagaMiddleware.run(fullSaga);

  return store;
}

export type AppStore = ReturnType<typeof createStore>;
export type RootState = ReturnType<AppStore["getState"]>;
export type AppDispatch = AppStore["dispatch"];

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppStore: () => AppStore = useStore;
