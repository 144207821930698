import { Flex, Text } from "@chakra-ui/react";
import { ReactNode } from "react";
import s from "./InputWrapper.module.scss";

interface Props {
  label: string;
  children: ReactNode;
  inputId?: string;
  errorMessage?: string;
}

export const InputWrapper = ({ label, children, errorMessage, inputId }: Props) => {
  return (
    <Flex className={s.formBox}>
      <label htmlFor={inputId}>
        <Text className={s.label}>{label}</Text>
      </label>
      {children}
      {errorMessage && <Text className={s.error}>{errorMessage}</Text>}
    </Flex>
  );
};
