import { AnyAction, combineReducers } from "@reduxjs/toolkit";
import { DominoAiState } from "./ApplicationState";
import { agentSlice } from "./components/Agent/agentSlice";

export const rootReducer = (state: DominoAiState | undefined, action: AnyAction) => {
  const combinedReducers = combineReducers<DominoAiState>({
    agentState: agentSlice.reducer,
  });
  return combinedReducers(state, action);
};
