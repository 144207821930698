import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface LoadingState {
  isLoading: string[];
}

const initialState: LoadingState = {
  isLoading: [],
};

export const loadingSlice = createSlice({
  name: "loadingSlice",
  initialState,
  reducers: {
    addLoadingState: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        isLoading: [...state.isLoading, action.payload],
      };
    },
    completedLoadingState: (state, action: PayloadAction<string>) => {
      const newLoadingArray = state.isLoading.filter(a => a !== action.payload);
      return {
        ...state,
        isLoading: newLoadingArray,
      };
    },
  },
});

export const { addLoadingState, completedLoadingState } = loadingSlice.actions;

export const selectLoadingState = (state: { loadingState: LoadingState }) => state.loadingState.isLoading;

export default loadingSlice.reducer;
