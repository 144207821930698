import { menuAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(menuAnatomy.keys);

const dominoMenu = definePartsStyle({
  left: 0,

  list: {
    padding: 0,
    width: "257px !important",
    borderRadius: "8px",
    margin: 0,
    maxHeight: "50vh !important",
    boxShadow: "none",
    overflowY: "scroll",
    overflowX: "hidden",
  },

  item: {
    margin: 0,
    fontSize: "15px",
    lineHeight: "123%",
    padding: "14px",
    height: "60px",
    backgroundColor: "#FFFFFF",

    _hover: {
      backgroundColor: "bgLight",
    },

    _active: {
      backgroundColor: "bgLight",
    },
  },
});

const dominoDotsMenu = definePartsStyle({
  button: {
    bg: "#FFFFFF",
    borderRadius: "4px",
    fill: "midDeepBlue",
    lineHeight: "32px",
    alignItems: "center",

    _hover: {
      bg: "defaultGrey",
      fill: "midDeepBlue",
    },
    _active: {
      bg: "defaultGrey",
      fill: "midDeepBlue",
    },
  },
  list: {
    py: "4px",
    minWidth: "132px",
    border: "1px solid #F1F4F9",
    boxShadow: "0px 4px 8px rgba(35, 43, 57, 0.08);",
    borderRadius: "4px",
    color: "midDeepBlue",
    fill: "midDeepBlue",
    overflow: "hidden",
  },
  item: {
    fontSize: "15px",
    color: "midDeepBlue",
    fill: "midDeepBlue",
    p: "8px",
    lineHeight: "20px",

    _hover: {
      backgroundColor: "bgLight",
    },

    _active: {
      backgroundColor: "bgLight",
    },
  },
});

const dominoDotsAdaptiveMenu = definePartsStyle({
  button: {
    bg: "#FFFFFF",
    borderRadius: "4px",
    fill: "midDeepBlue",
    lineHeight: "32px",
    alignItems: "center",

    _hover: {
      bg: "defaultGrey",
      fill: "midDeepBlue",
    },
    _active: {
      bg: "defaultGrey",
      fill: "midDeepBlue",
    },
  },
  list: {
    py: "4px",
    minWidth: "132px",
    maxHeight: "170px",
    border: "1px solid #F1F4F9",
    boxShadow: "0px 4px 8px rgba(35, 43, 57, 0.08);",
    borderRadius: "4px",
    color: "midDeepBlue",
    fill: "midDeepBlue",
    overflow: "auto",
  },
  item: {
    fontSize: "15px",
    color: "midDeepBlue",
    fill: "midDeepBlue",
    p: "8px",
    lineHeight: "20px",

    _hover: {
      backgroundColor: "bgLight",
    },

    _active: {
      backgroundColor: "bgLight",
    },
  },
});

const dominoBroadcastMenu = definePartsStyle({
  button: {
    bg: "#FFFFFF",
    borderRadius: "8px",
    fill: "midDeepBlue",
    lineHeight: "32px",
    alignItems: "center",

    _hover: {
      bg: "defaultGrey",
      fill: "midDeepBlue",
    },
    _active: {
      bg: "defaultGrey",
      fill: "midDeepBlue",
    },
  },
  list: {
    py: "4px",
    minWidth: "120px",
    border: "1px solid #DAE1FB",
    boxShadow: "0px 4px 8px rgba(35, 43, 57, 0.08);",
    borderRadius: "8px",
    color: "midDeepBlue",
    fill: "midDeepBlue",
    overflow: "hidden",
  },
  item: {
    fontSize: "15px",
    color: "midDeepBlue",
    fill: "midDeepBlue",
    p: "8px",
    lineHeight: "20px",

    _hover: {
      backgroundColor: "bgLight",
    },

    _active: {
      backgroundColor: "bgLight",
    },
  },
});

const dominoGroupedMenu = definePartsStyle({
  button: {
    bg: "#FFFFFF",
    borderRadius: "8px",
    fill: "#5A677D",

    _hover: {
      bg: "defaultGrey",
      fill: "midDeepBlue",
    },
    _active: {
      bg: "defaultGrey",
      fill: "midDeepBlue",
    },
  },
  list: {
    py: 0,
    width: "300px",
    border: "1px solid line",
    boxShadow: "0px 4px 8px rgba(35, 43, 57, 0.08)",
    borderRadius: "8px",
    color: "black",
    fill: "midDeepBlue",
    overflow: "hidden",
    zIndex: "2",
  },
  item: {
    height: "40px",
    fontSize: "15px",
    color: "black",

    _hover: {
      backgroundColor: "bgLight",
    },

    _active: {
      backgroundColor: "bgLight",
    },
  },

  groupTitle: {
    height: "20px",
    fontWeight: "700",
    fontSize: "16px",
    lineHeight: "20px",
    color: "black !important",
    marginLeft: "12px",
    textAlign: "left",
  },
});

const dominoGroupedModalMenu = definePartsStyle({
  button: {
    bg: "#FFFFFF",
    fill: "#5A677D",

    _hover: {
      bg: "defaultGrey",
      fill: "midDeepBlue",
    },
    _active: {
      bg: "defaultGrey",
      fill: "midDeepBlue",
    },
  },
  list: {
    py: 0,
    width: "300px",
    border: "1px solid line",
    boxShadow: "0px 4px 8px rgba(35, 43, 57, 0.08)",
    borderBottomRadius: "8px",
    color: "black",
    fill: "midDeepBlue",
    overflow: "hidden",
    zIndex: "2",
  },
  item: {
    height: "40px",
    fontSize: "15px",
    color: "black",

    _hover: {
      backgroundColor: "bgLight",
    },

    _active: {
      backgroundColor: "bgLight",
    },
  },

  groupTitle: {
    height: "20px",
    fontWeight: "700",
    fontSize: "16px",
    lineHeight: "20px",
    color: "black !important",
    marginLeft: "12px",
    textAlign: "left",
  },
});

const dominoDefaultMenu = definePartsStyle({
  left: 0,

  list: {
    padding: 0,
    width: "257px !important",
    borderRadius: "0 0 8px 8px",
    margin: 0,
    maxHeight: "30vh !important",
    boxShadow: "none",
    overflowY: "scroll",
    overflowX: "hidden",
    borderColor: "blueLink",
    borderTop: 0,
  },

  item: {
    margin: 0,
    fontSize: "15px",
    lineHeight: "123%",
    padding: "8px 16px",
    minHeight: "36px",
    backgroundColor: "#FFFFFF",

    _hover: {
      backgroundColor: "bgLight",
    },

    _active: {
      backgroundColor: "bgLight",
    },
  },
});

const dominoDurationMenu = definePartsStyle({
  button: {
    bg: "white !important",
    borderRadius: "8px",
    fill: "midDeepBlue",
    lineHeight: "32px",
    alignItems: "center",

    _hover: {
      bg: "defaultGrey",
      fill: "midDeepBlue",
    },
    _active: {
      bg: "defaultGrey",
      fill: "midDeepBlue",
    },
  },
  list: {
    py: "4px",
    minWidth: "120px",
    border: "1px solid #DAE1FB",
    boxShadow: "0px 4px 8px rgba(35, 43, 57, 0.08);",
    borderRadius: "8px",
    color: "midDeepBlue",
    fill: "midDeepBlue",
    overflow: "hidden",
  },
  item: {
    fontSize: "15px",
    color: "midDeepBlue",
    fill: "midDeepBlue",
    p: "8px",
    lineHeight: "20px",

    _hover: {
      backgroundColor: "bgLight",
    },

    _active: {
      backgroundColor: "bgLight",
    },
  },
});

export const menuTheme = defineMultiStyleConfig({
  variants: {
    dominoMenu,
    dominoDotsMenu,
    dominoDotsAdaptiveMenu,
    dominoBroadcastMenu,
    dominoGroupedMenu,
    dominoDefaultMenu,
    dominoDurationMenu,
    dominoGroupedModalMenu,
  },
});
